<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">报名列表（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程报名列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="realname"
                type="text"
                size="small"
                clearable
                placeholder="请输入学员姓名"
              />
            </div>
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                clearable
                type="text"
                size="small"                
                placeholder="请输入学员姓名"
              />
            </div>
            <div title="分享人" class="searchboxItem ci-full">
              <span class="itemLabel">分享人:</span>
              <el-select
                v-model="teacherName"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入关键词"
                size="small"
                :remote-method="remoteMethod"
              ><el-option
                  v-for="item in sharerPeopleList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </div>
            <div title="是否已联系" class="searchboxItem ci-full">
              <span class="itemLabel">是否已联系:</span>
              <el-select
                clearable
                size="small"
                v-model="isContact"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in isContactList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top: 5px">
            <div title="报名时间" class="searchboxItem ci-full">
              <span class="itemLabel">报名时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="signUpTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="学员姓名"
                align="center"
                prop="realname"
                show-overflow-tooltip
                min-width="80"
              />
              <el-table-column
                label="性别"
                align="center"
                prop="sex"
                show-overflow-tooltip
                min-width="60"
              >
                <template slot-scope="scope">
                  {{ scope.row.sex == "1" ? "男" : "女" }}
                </template>
              </el-table-column>
              <el-table-column
                label="课程名称"
                align="center"
                prop="courseName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="分享人"
                align="center"
                prop="shareUserName"
                show-overflow-tooltip
                min-width="70"
              />
              <el-table-column
                label="分享人手机号"
                align="center"
                prop="shareUserPhone"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="工作单位"
                align="center"
                prop="company"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="所属岗位"
                align="center"
                prop="post"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="行政区划"
                align="center"
                prop="areaName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="地址"
                align="center"
                prop="address"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="联系方式"
                align="center"
                prop="phone"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="报名时间"
                align="center"
                prop="applyTime"
                show-overflow-tooltip
                width="170"
                fixed="right"
              >
                <template slot-scope="scope">{{
                  scope.row.applyTime | moment
                }}</template>
              </el-table-column>
              <el-table-column
                label="是否联系"
                align="center"
                width="100px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-switch
                    :width="20"
                    v-model="scope.row.isContact"
                    @change="getSwitchUsable(scope.row.isContact, scope.row.id)"
                  ></el-switch>
                  <span>{{ scope.row.isContact ? "是" : "否" }}</span>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      realname: "", // 学员姓名
      courseName: "", // 课程名称
      teacherName: "", // 分享人
      sharerPeopleList: [], // 分享人 - 下拉数据
      isContact: null, // 是否联系
      // 是否联系 - 下拉数据
      isContactList: [
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      signUpTime: [], // 报名时间
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
  },
  methods: {
    // 获取分享人 - 下拉数据
    remoteMethod(query) {
      if (query !== '') {
        this.$post(
        "/teacher/mini-teacher/optionList",
        {
          teacherName: query
        },
        3000,
        false,
        2
      )
        .then((res) => {
          
          this.sharerPeopleList = res.data || []
        })
        .catch((err) => {
          return;
        });
      } else {
        this.sharerPeopleList = [];
      }
    },
    // 初始化获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        realname: this.realname,
        courseName: this.courseName,
        shareUserId: this.teacherName,
        isContact: this.isContact,
      };
      if (this.signUpTime) {
        params.applyStartTime = this.signUpTime[0];
        params.applyEndTime = this.signUpTime[1];
      }
      this.doFetch(
        {
          url: "/miniapp/course/share/apply/list",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 是否联系
    getSwitchUsable(keyId, enabled) {
      this.$post(
        "/miniapp/course/share/apply/modifyIsContact",
        {
          isContact: keyId,
          id: enabled,
        },
        3000,
        false,
        2
      )
        .then((ret) => {
          this.$message({
            type: "success",
            message: ret.message,
          });
          this.getData(-1);
        })
        .catch((err) => {
          return;
        });
    },
    // 导出
    handleCreate(stu, courseId = "") {
      let params = {
        isContact: this.isContact,
        realname: this.realname,
      };
      this.$post(
        "/miniapp/course/share/apply/list/export",
        params,
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == "0") {
          window.location.href = res.data;
        }
      });
    },
  },
  watch: {},
};
</script>
<style lang="less" scoped>
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
</style>